import React, { Component } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-v3";
import "./index.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Pages
const DefaultLayout = React.lazy(() => import("./containers/default-layout"));
const Coronavirus = React.lazy(() => import("./views/coronavirus"));

class App extends Component {
  componentDidMount() {
    if (loadReCaptcha) {
      loadReCaptcha("6Le3ReAUAAAAABk6S7cONGm5WLvVvZN1cddpWyfM");
    }
  }

  render() {
    return (
      <div>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/coronavirus"
                component={(props) => <Coronavirus />}
              />
              <Route path="/" component={(props) => <DefaultLayout />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </div>
    );
  }
}

export default App;
